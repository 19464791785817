<template>
  <div ref="outer" class="outer" :style="{ height: `${height}px` }">
    <div ref="inner" class="inner" :style="{ width: `${width}px` }">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useElementSize } from "@vueuse/core";

const outer = ref(null);
const inner = ref(null);

const { width } = useElementSize(outer);
const { height } = useElementSize(inner);
</script>

<style lang="scss" scoped>
.outer {
  width: 100%;
  position: relative;
}

.inner {
  position: absolute;
  overflow-x: visible;
}
</style>